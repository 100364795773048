import { createTheme, ThemeProvider } from "@mui/material/styles";

import { blue } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: blue[400],
      light: "#757ce8",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});

const CustomTheme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomTheme;
