import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import EInvoice from "./EInvoice";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import Header from "./Header";
import ErrorMessage from "./ErrorMessage";
import Beuszo from "./Beuszo";
import Footer from "./Footer";

const EInvoicePage = () => {
  const eInvoiceIdFromParam = useParams().eInvoiceId;

  const [eInvoice, setEInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const getEInvoice = async (eInvoiceId) => {
    setLoading(true);
    const url = `${process.env.REACT_APP_EINVOICE_SERVICE_URI}/api/EInvoice/${eInvoiceId}`;

    try {
      const response = await fetch(url);
      const responseData = await response.json();
      if (!response.ok) {
        setError(responseData.ErrorMessage);
        setLoading(false);
        return;
      }
      setEInvoice(responseData);
      setLoading(false);
      return responseData;
    } catch (err) {
      setError("Hiba történt az adatok betöltése közben");
      setLoading(false);
    }
  };

  useEffect(() => {
    getEInvoice(eInvoiceIdFromParam);
  }, [eInvoiceIdFromParam]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="stretch"
      gap={2}
      width="100%"
    >
      <Header />
      {error ? (
        <Box>
          <ErrorMessage message={error} />
        </Box>
      ) : loading || eInvoice == null ? (
        <Box
          height="100%"
          width="100% "
          position="absolute"
          top="50%"
          left="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ transform: "translate(-50%, -50%)" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <EInvoice
          eInvoice={eInvoice}
          firstDownloadCb={() => getEInvoice(eInvoiceIdFromParam)}
        />
      )}
      {/* <Beuszo /> */}
      <Footer />
    </Box>
  );
};

export default EInvoicePage;
