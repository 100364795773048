import React from "react";
import { Routes, Route } from "react-router-dom";

import HomePage from "./Components/HomePage";
import EInvoices from "./Components/EInvoices";
import EInvoicePage from "./Components/EInvoicePage";

const AppRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<HomePage />} /> */}
      {/* <Route path="/einvoices" element={<EInvoices />} /> */}
      <Route path="/einvoice/:eInvoiceId" element={<EInvoicePage />} />
    </Routes>
  );
};

export default AppRoutes;
