import { useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";

import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import ErrorMessage from "./ErrorMessage";

const EInvoice = ({ eInvoice, firstDownloadCb }) => {
  const [downloading, setIsDownloading] = useState(false);
  const [error, setError] = useState("");
  const downloadPdf = async (eInvoiceId) => {
    setIsDownloading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_EINVOICE_SERVICE_URI}/api/EInvoice/pdfDownload/${eInvoiceId}`
      );

      if (!response.ok) {
        const responseData = await response.json();
        setError(responseData.ErrorMessage);
        setIsDownloading(false);
        return;
      }

      const pdfBlob = await response.blob();
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${eInvoice.invoiceNumber}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      if (eInvoice.firstDownloadedAt === null) firstDownloadCb();

      setIsDownloading(false);
    } catch (error) {
      setError("Hiba az adatok letöltése közben");
      setIsDownloading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={3}
      padding={2}
    >
      <Card sx={{ width: "95%", background: "white", textAlign: "center" }}>
        <CardContent>
          <Typography variant="h6" color="text.primary">
            Számlakibocsátó
          </Typography>
          <Typography variant="subtitle2" color="text.primary">
            {eInvoice.issuer}
          </Typography>
        </CardContent>
      </Card>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={3}
        width="95%"
        padding={2}
        bgcolor="white"
        borderRadius={1}
      >
        <Typography gutterBottom variant="h5" component="h5">
          Számla adatai
        </Typography>

        <Grid container spacing={2} justifyContent="space-between">
          <Grid xs={6} item justifyContent="start">
            <Typography variant="h6" color="text.secondary">
              Számlaszám
            </Typography>
            <Typography variant="body2" color="text.primary">
              {eInvoice.invoiceNumber}
            </Typography>
          </Grid>
          <Grid xs={6} item>
            <Box display="flex" justifyContent="flex-end">
              {error ? (
                <ErrorMessage message={error} />
              ) : downloading ? (
                <CircularProgress />
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="flex-end"
                  gap={0.1}
                >
                  {eInvoice.firstDownloadedAt !== null && (
                    <Typography
                      textAlign="right"
                      variant="caption"
                      color="text.secondary"
                    >
                      A számlát korábban már letöltötték
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    onClick={() => downloadPdf(eInvoice.partitionKey)}
                  >
                    {eInvoice.firstDownloadedAt === null
                      ? "Letöltöm"
                      : "Újra letöltöm"}
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid xs={12} item textAlign="right">
            <Typography variant="caption " color="text.secondary">
              Fizetendő végösszeg
            </Typography>
            <Typography variant="h6" color="text.primary">
              {eInvoice.payableValue} {eInvoice.currency}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Typography
        variant="subtitle2"
        color="red"
        fontWeight={600}
        textAlign="center"
      >
        A számla letöltésének és tárolásának kötelezettsége annak címzettjét
        terheli!
      </Typography>
    </Box>
  );
};

export default EInvoice;
