import { AppBar, Box, Toolbar, Typography, Container } from "@mui/material";
import { ReactComponent as Kslogo } from "../assets/KSLogo.svg";

const Header = () => {
  return (
    <AppBar position="static" >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box m={0.2} p={1}>
            <Kslogo className="header-logo" />
          </Box>

          <Typography
            variant="h5"
            noWrap
            sx={{
              m: 2,
              display: { xs: "flex", md: "flex" },
              color: "inherit",
            }}
          >
            Számlaletöltő felület
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
