import AppRoutes from "./AppRoutes";
import CustomTheme from "./assets/CustomTheme";

const App = () => {
  return (
    <CustomTheme>
      <div className="App">
        <AppRoutes />
      </div>
    </CustomTheme>
  );
};

export default App;
