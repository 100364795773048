import React from "react";
import { ReactComponent as KSLogo } from "../assets/ks-logo-text.svg";
import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      position="fixed"
      bottom={0}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      mb="10px"
    >
      <Box >
        <KSLogo className="footer-logo"/>
      </Box>
      <Typography
        variant="body2"
        sx={{ fontWeight: 300, fontSize: 13, color: "#0f2341" }}
      >
        © 2023 Kulcs-Soft Nyrt.
      </Typography>
    </Box>
  );
};

export default Footer;
