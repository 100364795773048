import { Alert, AlertTitle } from "@mui/material";

const ErrorMessage = ({ message }) => {
  return (
    <Alert severity="error">
      <AlertTitle>Hiba</AlertTitle>
      {message}
    </Alert>
  );
};

export default ErrorMessage;
